import { all, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import fetchService from '../../../../../services/FetchService';
import getAppConfig from '../../../../utils/getAppConfig';
import { EVENT_TYPE } from '../../../../../constants/index';

const apiUrl = getAppConfig();
/*
 * Get Event List Saga
 */

export function* getEventListSaga({ language, isEditor }) {
  try {
    let response = yield fetchService({
      url: apiUrl.EVENT_API,
      method: 'POST',
      data: {
        contentType: EVENT_TYPE,
        language: language,
        facetFields: [],
        limit: 5,
        offset: 0,
        isPreviewMode: isEditor,
        filters: [
          {
            key: 'isfeatured',
            value: 'true',
          },
        ],
      },
    });
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.results &&
      response.data.data.results.length > 0
    ) {
      yield put(actions.getEventListSuccess(response.data.data.results));
    } else {
      yield put(actions.getEventListFail({ response: response.data }));
    }
  } catch (error) {
    yield put(
      actions.getEventListFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* watchGetEventList() {
  yield all([takeLatest(actionTypes.GET_EVENT_LIST_START, getEventListSaga)]);
}

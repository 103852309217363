import { all, put, takeLatest, delay } from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import getAppConfig from '../../../../utils/getAppConfig';
import fetchService from '../../../../../services/FetchService';

const apiUrl = getAppConfig();

export function* getItineraryPlacesResultSaga({ requestData }) {

  yield put(actions.getItineraryPlaceResultApiCalledStart());
  try {
    let response = yield fetchService({
      url: apiUrl.SOLR_FAVORITE_SEARCH_API,
      method: 'POST',
      data: requestData,
    });
    if (
      response &&
      response.data &&
      response.data.data &&
      Array.isArray(response.data.data.results)
    ) {
      yield put(actions.getItineraryPlaceResultSuccess({ items: response.data.data.results, itineraries: requestData.data }));
      yield delay(500);
      yield put(actions.getItineraryPlaceResultApiCalledFinished());
    }
  } catch (error) {
    yield put(actions.getItineraryPlaceResultApiCalledFinished());
    console.log('error', error)
  }
}



export function* watchItineraryMap() {
  yield all([takeLatest(actionTypes.GET_ITINERARY_PLACES_RESULT_START, getItineraryPlacesResultSaga)]);
}

/* eslint-disable max-len */
import React from 'react';
import { palette } from '../themes/vad/palette';

const MapIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 26 26"
    >
      <path d="M15,22L15,22L15,22c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2-0.1L9,19.1l-5.6,2.8c-0.3,0.2-0.7,0.1-1,0  C2.2,21.7,2,21.3,2,21V6c0-0.4,0.2-0.7,0.6-0.9l6-3C8.6,2.1,8.7,2,8.8,2C8.8,2,8.9,2,9,2c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0  c0.1,0,0.2,0,0.2,0.1L15,4.9l5.6-2.8c0.3-0.2,0.7-0.1,1,0S22,2.7,22,3v15c0,0.4-0.2,0.7-0.6,0.9l-6,3c-0.1,0-0.2,0.1-0.2,0.1  C15.2,22,15.1,22,15,22z M16,6.6v12.8l4-2V4.6L16,6.6z M10,17.4l4,2V6.6l-4-2V17.4z M4,6.6v12.8l4-2V4.6L4,6.6z" />
    </svg>
  );
};

export default MapIcon;

import isEmpty from 'lodash/isEmpty';
import { canUseDOM } from '../global/utils/canUseDOM';
//import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
//import { dataFetcher } from '../dataFetcher';
import { t } from 'i18next';
//import { sitecoreApiHost, sitecoreApiKey } from '../temp/config';

// Commenting code for sitecore event level analytics
// const trackingApiOptions = {
//   host: sitecoreApiHost,
//   querystringParams: {
//     sc_apikey: sitecoreApiKey,
//   },
//   fetcher: dataFetcher,
// };

const checkForDataLayerObj = () => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

export const pushAnaylyticsData = analyticsInfo => {
  if (canUseDOM) {
    const winObj = checkForDataLayerObj();
    const enableGoogleAnalytics = t && t('enableGoogleAnalytics');
    if (enableGoogleAnalytics && winObj) {
      winObj.push(analyticsInfo);
      // trackingApi
      //   .trackEvent(
      //     [{ ...{ eventId: analyticsInfo.event }, ...{ analyticsInfo } }],
      //     trackingApiOptions
      //   )
      //   .catch(error => console.log(error));
    }
  }
};

export const createDynamicLabels = labelValues => {
  let label = '';
  if (Array.isArray(labelValues) && labelValues.length > 0) {
    label = labelValues.join('|');
  }
  return label;
}

export const insertAnalyticsInfo = (analyticsInfoObj = null) => {
  if (isEmpty(analyticsInfoObj)) return;
  pushAnaylyticsData(analyticsInfoObj);
}

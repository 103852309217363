export const checkboxType = 'checkbox';
export const dropdownType = 'dropdown';
export const calendarType = 'calendar';
export const priceTaxonomyKey = 'filter_dining_price';
export const regionTaxonomyKey = 'filter_region';
export const tripadvisorratingTaxonomyKey = 'tripadvisorrating';

export const dictionaryKeys = {
  filtersText: 'filtersText',
  resetText: 'resetText',
  seeLessText: 'seeLessText',
  seeMoreText: 'seeMoreText',
  selectedFilterText: 'selectedFilterText',
  applyText: 'applyText',
  clearAllText: 'clearAllText',
  resetAllText: 'resetAllText'
};

export const dateRangeKeys = {
  startDate: 'startDate',
  endDate: 'endDate',
  startDateAPIKey: 'startdt_t',
  endDateAPIKey: 'enddt_t',
  dateRangeField: 'startdt_t|enddt_t',
};

export const FILTERS_TO_AVOID_IN_AD_CALENDAR = ['eventcategories', 'dateCategories', 'startdt_t|enddt_t', 'period', 'startDate', 'endDate']

import React, { forwardRef, useRef, useImperativeHandle, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import DctSvgIcon from '../Svg';
import Link from '../Link';
import useStyles from './Button.style';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const containedButtons = [
  'form',
  'playBtn',
  'whitePillContrast',
]

const ButtonMUI = forwardRef((props, ref) => {
  const {
    className,
    children,
    iconOnly,
    size,
    iconType,
    buttonType,
    colorVariant,
    srText,
    field,
    buttonIcon,
    hasBorder,
    type,
    iconName,
    endIcon,
    endIconSvg = null,
    isStartIcon = false,
    hideEndIcon = false,
    animateSettings = {},
    wrapInner = false,
    themeColor,
    ariaExpanded = null,
    linkCustomStyle = '',
    ...other
  } = props;
  const classes = useStyles(props);

  const isContainedButton = containedButtons.indexOf(buttonType) > -1;

  const {
    txtDuration = '0.625s',
    txtDurationDelay = '0s',
    btnIconDuration = '0.250s',
    btnIconDelay = '0.250s',
    selfTrigger = true,
    willAnimate = true,
  } = animateSettings;
  const buttonAnimating = useRef(null),
    txtLayerAnimate = useRef(null),
    txtLayerAnimateWrapper = useRef(null);

  let textAnim, btnIconAnim;
  useImperativeHandle(ref, () => ({
    buttonAnimating,
    animPlay() {
      textAnim && textAnim.play();
      btnIconAnim && btnIconAnim.play();
    },
    animReverse() {
      textAnim && textAnim.reverse();
      btnIconAnim && btnIconAnim.reverse();
    },
  }));

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (buttonAnimating.current && willAnimate && buttonType === 'primary') {
      setTimeout(() => {
        let buttonAnimatingIcon = buttonAnimating.current && buttonAnimating.current.getElementsByClassName('MuiButton-endIcon')[0];
        if (buttonAnimatingIcon) {
          gsap.set(buttonAnimatingIcon, {
            y: '16px',
            opacity: 0,
          });
          btnIconAnim = gsap.timeline();
          btnIconAnim.fromTo(
            buttonAnimatingIcon,
            {
              y: '16px',
              opacity: 0,
              ease: 'power1.out',
              paused: true,
              duration: btnIconDuration,
            },
            {
              y: '0px',
              opacity: 1,
            },
            `+=${btnIconDelay}`
          );
          btnIconAnim.pause();
          let skewDeg = '3deg';
          // set less skew if div is more than 30% of winWidth
          if (typeof window !== 'undefined') {
            txtLayerAnimate.current && txtLayerAnimate.current.offsetWidth && window.innerWidth * 0.3 < txtLayerAnimate.current.offsetWidth && (skewDeg = '1.5deg');
          }
          gsap.set(txtLayerAnimate.current, {
            skewY: skewDeg,
            opacity: 0,
            transformOrigin: 'center left',
          });
          textAnim = gsap.timeline();
          textAnim.fromTo(
            txtLayerAnimate.current,
            {
              skewY: skewDeg,
              y: '16px',
              opacity: 0,
              ease: 'power1.out',
              paused: true,
              duration: txtDuration,
            },
            {
              skewY: '0deg',
              y: '0px',
              opacity: 1,
            },
            `+=${txtDurationDelay}`
          );
          textAnim.pause();

          if (selfTrigger) {
            let delayPlayPause, delayPlayPause1;
            let haveAnimatedOnce = false;
            gsap.timeline({
              scrollTrigger: {
                trigger: buttonAnimating.current,
                start: 'top bottom',
                end: `+=${(window.androidHeight || window.innerHeight) * 0.2}px bottom`,
                scrub: true,
                once: true,
                onUpdate: self => {
                  clearTimeout(delayPlayPause);
                  clearTimeout(delayPlayPause1);
                  if (self.progress > 0.15) {
                    delayPlayPause = setTimeout(() => btnIconAnim.play(), 150);
                    delayPlayPause1 = setTimeout(() => textAnim.play(), 150);
                    haveAnimatedOnce = true;
                  } else if (haveAnimatedOnce) {
                    delayPlayPause = setTimeout(() => btnIconAnim.reverse(), 150);
                    delayPlayPause1 = setTimeout(() => textAnim.reverse(), 150);
                  }
                },
              },
            });
          }
        }
      }, 2000);
    }
  }, []);

  if (props.from === 'notficationBanner' && !isEmpty(field) && !get(field, 'value.href', '')) {
    return <></>
  }

  const ariaLabel = useMemo(() => {
    return field.value?.text?.props?.children?.props?.field?.value || field.value?.text || null
  }, [field])

  const buttonVariant = (
    <Button
      classes={{
        root: `${classes.root}  ${classes[buttonType]} ${colorVariant !== undefined && classes[colorVariant]} ${className} ${hasBorder ? 'btnWithBorder' : ''
          }`,
      }}
      ref={buttonAnimating}
      variant={isContainedButton ? 'contained' : 'text'}
      size={size}
      endIcon={
        !hideEndIcon && !isStartIcon && (
          <Icon className={buttonType === 'playBtn' ? 'playBtnSvg' : ''}>
            {/* <DctSvgIcon name={iconName ? iconName : endIcon} /> */}
            {endIconSvg ? endIconSvg : <DctSvgIcon name={iconName ? iconName : endIcon} />}
          </Icon>
        )
      }
      startIcon={
        isStartIcon && (
          <Icon>
            <DctSvgIcon name={iconName ? iconName : 'ArrowForwardSharpIcon'} />
          </Icon>
        )
      }
      type={type ? type : 'button'}
      color={["default", "inherit", "primary", "secondary"].includes(buttonType) ? buttonType : "default"}
      aria-label={srText || ariaLabel || children || get(field, 'value.text', '')}
      tabIndex={!isEmpty(field) && get(field, 'value.href', '') ? '-1' : '0'}
      {...other}
    >
      <ConditionalWrapper
        condition={willAnimate && buttonType === 'primary'}
        wrapper={children => (
          <div className={`${classes.scrollAnimateWrapper}`} ref={txtLayerAnimateWrapper}>
            <div className={classes.scrollAnimate} ref={txtLayerAnimate}>
              {children}
            </div>
          </div>
        )}
      >
        <ConditionalWrapper
          condition={wrapInner}
          wrapper={children => (
            <div className={'wrapInner'}>
              {children}
            </div>
          )}
        >
          {buttonIcon} <span className='btn-txt'>{children || get(field, 'value.text')}</span>
        </ConditionalWrapper>
      </ConditionalWrapper>
    </Button>
  );

  if (iconOnly) {
    return (
      <IconButton
        classes={{
          root: `${classes[iconType]} ${className}`,
        }}
        aria-label={srText}
        size={size}
        aria-expanded={ariaExpanded ?? ''}
        {...other}
      >
        {children}
      </IconButton>
    );
  }

  if (!isEmpty(field) && !other.disabled && get(field, 'value.href', '')) {
    return (
      <Link
        className={linkCustomStyle}
        field={field}
        target={field.value.target || field.value.linktype === 'external' ? '_blank' : ''}
        srText={get(field, 'value.text', '')}
      >
        {buttonVariant}
      </Link>
    );
  }

  return buttonVariant;
});
ButtonMUI.displayName = 'ButtonMUI';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

ButtonMUI.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  iconOnly: PropTypes.bool,
  iconType: PropTypes.string,
  size: PropTypes.string,
  buttonType: PropTypes.string,
  colorVariant: PropTypes.string,
  srText: PropTypes.string,
  hasBorder: PropTypes.bool,
  iconName: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.object,
  }),
  endIcon: PropTypes.string,
  themeColor: PropTypes.string,
};

ButtonMUI.defaultProps = {
  className: '',
  children: null,
  iconOnly: false,
  hasBorder: true,
  iconType: '',
  size: 'medium',
  buttonType: 'primary',
  colorVariant: undefined,
  srText: '',
  iconName: '',
  field: {
    value: {},
  },
  endIcon: 'ArrowForwardSharpIcon',
  themeColor: '',
};

export default ButtonMUI;

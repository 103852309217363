import React from 'react';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { withNamespaces } from 'react-i18next';

import {
  HOME_PAGE_ROUTE_NAME,
  DOMAIN_SUFFIX,
  INSIDE_CHINA_COUNTRY_CODE,
  OUTSIDE_CHINA_COUNTRY_CODE,
} from '../../../../constants';
import { ATTRIBUTE_TYPE } from '../../../../constants/index';
import { getStringToShowInCalendar as escapeSpecialCharFromString } from '../../../utils/stringFunctions';

const OpenGraphMetaInfo = ({ route, t: dictionary, appConfig, context }) => {
  // meta tag details
  let pageDescription =
    get(route, 'fields.pageDescription.value', '') ||
    get(route, 'fields.description.value', '');
  let ogSiteName = get(route, 'fields.ogSiteName.value', '');
  const metaKeywords = get(route, 'fields.metaKeywords.value', '');
  let pageTitle =
    get(route, 'fields.pageTitle.value', '') ||
    get(route, 'fields.name.value', '');
  // extracting pagename to check if not home page then will append
  const pageName = get(route, 'name', '');
  const isHomePage =
    pageName && pageName.toLowerCase() === HOME_PAGE_ROUTE_NAME.toLowerCase();
  const contentType = get(
    route,
    'fields.contentType.fields.taxonomyKey.value',
    ''
  );
  if (contentType && contentType.toLowerCase() === ATTRIBUTE_TYPE) {
    pageTitle = context && context.tag;
  }
  if (!isHomePage) {
    const metaPageTitleSuffix = dictionary && dictionary('metaPageTitleSuffix');
    if (metaPageTitleSuffix !== 'metaPageTitleSuffix') {
      pageTitle = `${pageTitle} ${metaPageTitleSuffix}`;
    }
  }

  const eventContentType = dictionary && dictionary('eventContentType');
  let image = '';
  if (contentType === eventContentType) {
    image = get(route, 'fields.thumbnailImageUrl.value.src', '');
  }
  if (!image) {
    if (get(route, 'fields.ogImage.value.src', '')) {
      image = get(route, 'fields.ogImage.value.src', '');
    } else if (
      get(route, 'fields.gallery[0].fields.image.value.src', '')
    ) {
      image = get(route, 'fields.gallery[0].fields.image.value.src', '');
    } else if (
      get(route, 'fields.gallery[1].fields.image.value.src', '')
    ) {
      image = get(route, 'fields.gallery[1].fields.image.value.src', '');
    } else {
      image = get(route, 'fields.image.value.src', '');
    }

    if (image && appConfig.appOrigin) {
      image = `${appConfig.appOrigin}${image}`;
    }
  }

  if (!image) {
    const mainPlaceholder = get(
      route,
      'placeholders["vad-main"]',
      '[]'
    );
    if (mainPlaceholder.length > 0) {
      const heroCarouselComponent = mainPlaceholder.find(comp => comp.componentName === 'HeroCarousel');
      if (heroCarouselComponent) {
        image = get(heroCarouselComponent, 'fields.carouselItems[0].fields.webImage.value.src', '');
      } else {
        const dualHeroSliderComponent = mainPlaceholder.find(comp => comp.componentName === 'DualHeroSlider');
        if (dualHeroSliderComponent) {
          image = get(dualHeroSliderComponent, 'fields.Slides[0].fields.image.value.src', '');
        }
      }
    }
  }

  const langVersion = context && context.pageLanguageVersions;

  const filterLanguageContent = language => {
    let langUrl =
      langVersion &&
      langVersion.length > 0 &&
      langVersion.filter(
        item => item && item.code && item.code.toLowerCase() === language
      );

    return langUrl && langUrl[0] && langUrl[0].url;
  };

  const currentLanguage =
    context && context.language && context.language.toLowerCase();

  const pageUrl = filterLanguageContent(currentLanguage);
  let canonicalPageUrl = pageUrl
  // remove /things-to-do/itineraries/the-complete-abu-dhabi-experience from page url to get canonical url
  if (pageUrl && pageUrl.includes('/things-to-do/itineraries/the-complete-abu-dhabi-experience')) {
    canonicalPageUrl = pageUrl.replace('/things-to-do/itineraries/the-complete-abu-dhabi-experience', '')
  }

  let fullUrl, urlOrigin, defaultUrl;

  if (appConfig && appConfig.appOrigin) {
    fullUrl = new URL(appConfig.appOrigin);
  }

  const domainName = fullUrl && fullUrl.hostname;

  const domainSuffix =
    domainName && domainName.substr(domainName.lastIndexOf('.'));

  const domainProtocol = fullUrl && fullUrl.protocol;

  const x_defaultUrl = filterLanguageContent('en');
  if (domainSuffix === DOMAIN_SUFFIX.CHINA) {
    defaultUrl = filterLanguageContent('en');
    urlOrigin = domainName && domainName.split('.');
    urlOrigin &&
      urlOrigin.length > 0 &&
      urlOrigin.splice(-1, 1, OUTSIDE_CHINA_COUNTRY_CODE);
    urlOrigin = urlOrigin && urlOrigin.join('.');
  } else {
    defaultUrl = filterLanguageContent('zh');
    urlOrigin = domainName && domainName.split('.');
    urlOrigin &&
      urlOrigin.length > 0 &&
      urlOrigin.splice(-1, 1, INSIDE_CHINA_COUNTRY_CODE);
    urlOrigin = urlOrigin && urlOrigin.join('.');
  }

  const filterOutCurrentLanguage =
    langVersion &&
    langVersion.length > 0 &&
    langVersion.filter(
      item => item && item.code && item.code.toLowerCase() !== currentLanguage
    );

  const facebookDomainVerification = dictionary && dictionary('facebook-domain-verification');
  const metaBaiduVerification = dictionary && dictionary('meta-baidu-site-verification');
  const metaYandexVerification = dictionary && dictionary('meta-yandex-verification');
  /*
    Commenting out canonical, includeInSitemap temporarily
  */
  // const canonical = get(route, 'fields.canonicalUrl.value.href', undefined);
  // const includeInSitemap = get(route, 'fields.includeInSitemap.value', false);
  pageTitle = escapeSpecialCharFromString(pageTitle);
  pageDescription = escapeSpecialCharFromString(pageDescription);
  ogSiteName = escapeSpecialCharFromString(ogSiteName);

  const default_urlOrigin = 'en'
  return (
    <Helmet>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <title>{pageTitle}</title>
      {langVersion &&
        langVersion.length > 0 &&
        langVersion.map(item => {
          let langCode = item && item.code && item.code.split('-');
          langCode = langCode.length > 0 && langCode[0];
          return (
            <link
              key={JSON.stringify(item)}
              rel="alternate"
              hrefLang={langCode}
              href={`${domainProtocol}//${urlOrigin}/${item && item.url}`}
            />
          );
        })}

      {filterOutCurrentLanguage &&
        filterOutCurrentLanguage.length > 0 &&
        filterOutCurrentLanguage.map(item => {
          let langCode = item && item.code && item.code.split('-');
          langCode = langCode.length > 0 && langCode[0];
          return (
            <link
              key={JSON.stringify(item)}
              rel="alternate"
              hrefLang={langCode}
              href={`${domainProtocol}//${domainName}/${item && item.url}`}
            />
          );
        })}

      {defaultUrl && (
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${domainProtocol}//${urlOrigin}/${x_defaultUrl}`}
        />
      )}

      {/* General meta tags */}
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      {pageDescription && <meta name="description" content={pageDescription} />}

      {pageUrl && (
        <link
          rel="canonical"
          href={`${appConfig && appConfig.appOrigin}/${canonicalPageUrl}`}
        />
      )}
      {/* <meta
        name="robots"
        content={includeInSitemap ? 'index, follow' : 'noindex, nofollow'}
      /> */}

      {/* Tags for facecbook */}
      {pageTitle && <meta name="og:title" content={pageTitle} />}
      {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
      {image && <meta name="og:image" content={image} />}
      {pageDescription && (
        <meta name="og:description" content={pageDescription} />
      )}
      {pageUrl && (
        <meta
          name="og:url"
          content={`${appConfig && appConfig.appOrigin}/${pageUrl}`}
        />
      )}

      {/* Tags for twitter */}
      {pageTitle && <meta name="twitter:title" content={pageTitle} />}
      {image && <meta name="twitter:image" content={image} />}
      {image && <meta name="twitter:image:src" content={image} />}
      {pageDescription && (
        <meta name="twitter:description" content={pageDescription} />
      )}
      <meta name="referrer" content="strict-origin-when-cross-origin" />
      {facebookDomainVerification && <meta name="facebook-domain-verification" content={facebookDomainVerification} />}
      {metaBaiduVerification && <meta name="baidu-site-verification" content={metaBaiduVerification} />}
      {metaYandexVerification && <meta name="yandex-verification" content={metaYandexVerification} />}
    </Helmet>
  );
};

export default withNamespaces()(OpenGraphMetaInfo);

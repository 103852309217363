/* eslint-disable max-len */
import React from 'react';

export const TwitterIcon = props => {
  return (
    <svg className="twitter" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_13739_8741)">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.7438 9.00277C17.7438 13.8336 13.8277 17.7497 8.99691 17.7497C4.16613 17.7497 0.25 13.8336 0.25 9.00277C0.25 4.17198 4.16613 0.255859 8.99691 0.255859C13.8277 0.255859 17.7438 4.17198 17.7438 9.00277ZM7.78948 9.52524L3.60856 3.93506H6.83089L9.57183 7.59988L12.9641 3.93506H13.911L9.99488 8.16577L14.4111 14.0703H11.1888L8.21272 10.091L4.52918 14.0703H3.58221L7.78948 9.52524ZM6.48143 4.63251H5.00111L11.5381 13.3729H13.0184L6.48143 4.63251Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_13739_8741">
          <rect width="17.5" height="17.5" fill="white" transform="translate(0.25 0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
};
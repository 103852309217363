import * as actionTypes from './actionTypes';

export const getItineraryPlaceResultApiCalledStart = () => {
  return {
    type: actionTypes.GET_ITINERARY_PLACES_RESULT_API_CALL_START,
  };
};

export const getItineraryPlaceResultStart = requestData => {
  return {
    type: actionTypes.GET_ITINERARY_PLACES_RESULT_START,
    requestData,
  };
};

export const getItineraryPlaceResultSuccess = requestData => {
  return {
    type: actionTypes.GET_ITINERARY_PLACES_RESULT_SUCCESS,
    requestData,
  };
};

export const getItineraryPlaceResultApiCalledFinished = () => {
  return {
    type: actionTypes.GET_ITINERARY_PLACES_RESULT_API_CALL_FINISHED,
  };
};

export const updateItineraryPlaceResult = requestData => {
  return {
    type: actionTypes.UPDATE_ITINERARY_PLACES_RESULT,
    requestData,
  };
};

export const updatePreviewItemResult = requestData => {
  return {
    type: actionTypes.UPDATE_PREVIEW_ITEM_RESULT,
    requestData,
  };
};

export const selectedAddPlaceItem = requestData => {
  return {
    type: actionTypes.SELECTED_ADD_PLACE_ITEM,
    requestData,
  };
};


export const previewItemIsSearching = requestData => {
  return {
    type: actionTypes.ITINERARY_PLACES_IS_SEARCHING,
    requestData,
  };
};

export const setPlaceListingItems = requestData => {
  return {
    type: actionTypes.SET_PLACE_LISTING_ITEMS,
    requestData,
  };
};

export const setFavoriteSectionItineraryAdded = requestData => {
  return {
    type: actionTypes.FAVORITE_SECTION_ITINERARY_ADDED,
    requestData,
  };
};

export const setFavoriteSectionItineraryAddedFinished = requestData => {
  return {
    type: actionTypes.FAVORITE_SECTION_ITINERARY_ADDED_FINISHED,
    requestData,
  };
};

export const toggleItineraryModal = requestData => {
  return {
    type: actionTypes.TOGGLE_ITINERARY_MODAL,
    requestData,
  };
};

export const isItineraryModalOpen = requestData => {
  return {
    type: actionTypes.IS_ITINERARY_MODAL_OPEN,
    requestData,
  };
};
/* eslint-disable max-len */
import React from 'react';
import { palette } from '../themes/vad/palette';

const SearchIcon = props => {
  return (
    <svg
      version="1.1"
      xlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="2 2 21 21"
    >
      <path d="M18.6533 16.98L15.9457 14.3016C16.686 13.1925 17.0343 11.8678 16.9355 10.538C16.8367 9.20826 16.2964 7.94963 15.4004 6.9621C14.5043 5.97457 13.304 5.31477 11.9901 5.08751C10.6761 4.86026 9.32394 5.07858 8.14829 5.7078C6.97264 6.33703 6.04096 7.34106 5.50126 8.5604C4.96155 9.77974 4.84478 11.1445 5.16949 12.4378C5.4942 13.7311 6.24176 14.8788 7.29343 15.6986C8.3451 16.5184 9.64055 16.9633 10.974 16.9625C12.1574 16.9637 13.314 16.61 14.2943 15.9472L17.0078 18.6606C17.2264 18.878 17.5222 19 17.8306 19C18.1389 19 18.4347 18.878 18.6533 18.6606C18.7663 18.5518 18.8561 18.4212 18.9175 18.2769C18.9789 18.1325 19.0105 17.9772 19.0105 17.8203C19.0105 17.6635 18.9789 17.5082 18.9175 17.3638C18.8561 17.2194 18.7663 17.0889 18.6533 16.98ZM10.974 6.71561C11.8105 6.71561 12.6282 6.96359 13.3238 7.4282C14.0194 7.89281 14.5617 8.5532 14.882 9.32591C15.2024 10.0986 15.2865 10.949 15.1238 11.7695C14.961 12.59 14.5587 13.3438 13.9676 13.9357C13.3765 14.5276 12.6232 14.931 11.8029 15.0949C10.9826 15.2588 10.1322 15.1758 9.35903 14.8565C8.58588 14.5372 7.92474 13.9959 7.45917 13.3009C6.9936 12.606 6.7445 11.7886 6.74334 10.9521C6.74334 9.82952 7.18888 8.75283 7.98212 7.95851C8.77535 7.16418 9.85142 6.71715 10.974 6.71561Z" />
    </svg>
  );
};

export default SearchIcon;

import * as actionTypes from './actionTypes';
import { getLocalStorage } from '../../../../../utils/localStorage';
import { getItineraryData } from '../ItenaryMapFloatingBox/common';
/**
 *  Initial ITINERARYMap Reducer
 */
const initialState = {
  placesListingItem: [],
  placesItinerary: null,
  isPlacesItineraryFetching: false,
  previewItems: [],
  isSearching: false,
  selectedAddPlaceItem: [],
  favSectionItinerary: null,
  showItineraryModal: false,
  isItineraryModalOpen: false
};

const getMultiFilterResultSuccess = (state, action) => {
  const response = getItineraryData(action.requestData.items)

  const itineraryList = JSON.parse(getLocalStorage('itineraryList') || '[]')
  const mapping = {};
  response.forEach(obj => {
    mapping[obj.itemUniqueKey] = obj;
  });

  const listing = action.requestData?.itineraries || itineraryList
  // const newItineraryList = itineraryList.map(obj => {
  const newItineraryList = listing.map(obj => {
    const newDays = {};
    Object.keys(obj.newdays).forEach(date => {
      newDays[date] = obj.newdays[date].map(id => ({ ...mapping[id], uniqueId: (Math.floor(Math.random() * 99999) + 1).toString() }));
    });
    return { ...obj, newdays: newDays };
  });

  return {
    ...state,
    placesItinerary: newItineraryList,
  };
};

const updatePlaceItinerary = (state, action) => {
  return {
    ...state,
    placesItinerary: action.requestData,
  };
}

const updatePreviewItemResult = (state, action) => {
  if (action.requestData.length === state.previewItems.length) return { ...state }
  return {
    ...state,
    previewItems: action.requestData,
  };
}

const updateSelectedAddPlaceItem = (state, action) => {
  return {
    ...state,
    selectedAddPlaceItem: action.requestData,
  };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ITINERARY_PLACES_RESULT_API_CALL_START:
      return { ...state, isPlacesItineraryFetching: true };
    case actionTypes.GET_ITINERARY_PLACES_RESULT_SUCCESS:
      return getMultiFilterResultSuccess(state, action);
    case actionTypes.GET_ITINERARY_PLACES_RESULT_API_CALL_FINISHED:
      return { ...state, isPlacesItineraryFetching: false };
    case actionTypes.UPDATE_ITINERARY_PLACES_RESULT:
      return updatePlaceItinerary(state, action);
    case actionTypes.UPDATE_PREVIEW_ITEM_RESULT:
      return updatePreviewItemResult(state, action);
    case actionTypes.SELECTED_ADD_PLACE_ITEM:
      return updateSelectedAddPlaceItem(state, action);
    case actionTypes.ITINERARY_PLACES_IS_SEARCHING:
      return { ...state, isSearching: action.requestData };
    case actionTypes.SET_PLACE_LISTING_ITEMS:
      return { ...state, placesListingItem: action.requestData };
    case actionTypes.FAVORITE_SECTION_ITINERARY_ADDED:
      return { ...state, favSectionItinerary: action.requestData };
    case actionTypes.FAVORITE_SECTION_ITINERARY_ADDED_FINISHED:
      return { ...state, favSectionItinerary: null };
    case actionTypes.TOGGLE_ITINERARY_MODAL:
      return { ...state, showItineraryModal: action.requestData };
    case actionTypes.IS_ITINERARY_MODAL_OPEN:
      return { ...state, isItineraryModalOpen: action.requestData };
    default:
      return state;
  }
};

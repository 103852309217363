/* Const defination */
export const BASE_FONT_SIZE = 16;
export const MAX_WIDTH = 1440;
export const SPACING_MULTIPLIER = 8;

/* Function */
export const pxToRem = pixelValue => `${pixelValue / BASE_FONT_SIZE}rem`;
export const space = (num = 1, suffix = false) =>
  suffix ? `${num * SPACING_MULTIPLIER}px` : num * SPACING_MULTIPLIER;

/* Global preset variable items */

export const GUTTERS = {
  none: 0,
  sm: space(2) / 2,
  lg: space(3) / 2,
  xl: space(4) / 2,
};

export const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'],
  values: {
    xs: 0,
    sm: 600,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1440,
    xxxl: 2048,
  },
};

export const desktopMedia = `@media (min-width: ${breakpoints.values.lg}px)`;
export const tabletLandscapeMedia = `@media (min-width: ${breakpoints.values.lg}px) and (max-width: 1200px)`;
export const mobileMedia = `@media (max-width: ${breakpoints.values.lg - 1}px)`;

export const easing = (ease, prop = 'all') => {
  const easingArr = {
    in: `${prop} 0.3s cubic-bezier(0.755, 0.050, 0.855, 0.060)`,
    out: `${prop} 0.3s cubic-bezier(0.230, 1.000, 0.320, 1.000)`,
    inOut: `${prop} 0.3s cubic-bezier(0.860, 0.000, 0.070, 1.000)`,
  };
  return easingArr[ease];
};

export const arabicLang = 'ar';
export const hebrewLang = 'he';
export const russianLang = 'ru';
export const chineseLang = 'zh';
export const koreanLang = 'ko';
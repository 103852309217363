export const GET_ITINERARY_PLACES_RESULT_API_CALL_START = 'GET_ITINERARY_PLACES_RESULT_API_CALL_START';
export const GET_ITINERARY_PLACES_RESULT_START = 'GET_ITINERARY_PLACES_RESULT_START';
export const GET_ITINERARY_PLACES_RESULT_SUCCESS = 'GET_ITINERARY_PLACES_RESULT_SUCCESS';
export const GET_ITINERARY_PLACES_RESULT_API_CALL_FINISHED = 'GET_ITINERARY_PLACES_RESULT_API_CALL_FINISHED';
export const UPDATE_ITINERARY_PLACES_RESULT = 'UPDATE_ITINERARY_PLACES_RESULT';
export const UPDATE_PREVIEW_ITEM_RESULT = 'UPDATE_PREVIEW_ITEM_RESULT';
export const ITINERARY_PLACES_IS_SEARCHING = 'ITINERARY_PLACES_IS_SEARCHING';
export const SELECTED_ADD_PLACE_ITEM = 'SELECTED_ADD_PLACE_ITEM';
export const SET_PLACE_LISTING_ITEMS = 'SET_PLACE_LISTING_ITEMS';
export const FAVORITE_SECTION_ITINERARY_ADDED = 'FAVORITE_SECTION_ITINERARY_ADDED';
export const FAVORITE_SECTION_ITINERARY_ADDED_FINISHED = 'FAVORITE_SECTION_ITINERARY_ADDED_FINISHED';
export const TOGGLE_ITINERARY_MODAL = 'TOGGLE_ITINERARY_MODAL';
export const IS_ITINERARY_MODAL_OPEN = 'IS_ITINERARY_MODAL_OPEN';

const getAllCategories = (categoriesList) => {
  const allCategoryList = []
  categoriesList.map(category => {
    const _categoryList = category.fields.CategoryList;
    const mappedCategoryList = _categoryList.map(
      cat => cat.fields.taxonomyKey.value
    );
    allCategoryList.push(...mappedCategoryList)

  });
  return allCategoryList;
}

const getItineraryData = (items) => {
  const result = Array.isArray(items) && items.map(val => {
    const { id, name, itemUniqueKey, latitude, longitude, googlePlaceId, isFeatured, itemUrl, location, category, thumbnailImage, coordinates } = val || {}
    return {
      ...(id && { id }), name, ...(itemUniqueKey && { itemUniqueKey }), ...(latitude && { latitude }), ...(longitude && { longitude }), googlePlaceId, ...(isFeatured && { isFeatured }), itemUrl, location, ...(category && { category }), thumbnailImage, ...(coordinates && { coordinates })
    }
  })
  return result
}

export {
  getAllCategories, getItineraryData
}
import { all, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import fetchService from '../../../../../services/FetchService';
import getAppConfig from '../../../../utils/getAppConfig';

const apiUrl = getAppConfig();
/*
 * Get Similar Search Saga
 */

export function* getOtherBranchesSaga({ requestData }) {
  try {
    let response = yield fetchService({
      url: apiUrl.SIMILAR_SEARCH_API,
      method: 'POST',
      data: requestData,
    });
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.results &&
      response.data.data.results.length > 0
    ) {
      yield put(actions.getOtherBranchesSuccess(response.data.data.results));
    } else {
      yield put(actions.getOtherBranchesFail({ response: response.data }));
    }
  } catch (error) {
    yield put(
      actions.getOtherBranchesFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* getMoreLikeThisSaga({ requestData }) {
  try {
    let response = yield fetchService({
      url: apiUrl.SIMILAR_SEARCH_API,
      method: 'POST',
      data: requestData,
    });
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.results &&
      response.data.data.results.length > 0
    ) {
      // yield put(actions.getMoreLikeThisSuccess(response.data.data.results));
      yield put(actions.getMoreLikeThisSuccess(response.data.data));
    } else {
      yield put(actions.getMoreLikeThisFail({ response: response.data }));
    }
  } catch (error) {
    yield put(
      actions.getMoreLikeThisFail({
        status: error.response ? error.response.status : 404,
        message: error.message || 'Network Request Failed',
      })
    );
  }
}

export function* watchSimilarSearch() {
  yield all([
    takeLatest(actionTypes.GET_OTHER_BRANCHES_START, getOtherBranchesSaga),
    takeLatest(actionTypes.GET_MORE_LIKE_THIS_START, getMoreLikeThisSaga),
  ]);
}

import * as actionTypes from './actionTypes';

export const getMultiFilterResultApiCalledStart = () => {
  return {
    type: actionTypes.GET_MULTIFILTER_RESULT_API_CALL_START,
  };
};

export const getMultiFilterResultStart = requestData => {
  return {
    type: actionTypes.GET_MULTIFILTER_RESULT_START,
    requestData,
  };
};

export const getMultiFilterResultSuccess = requestData => {
  return {
    type: actionTypes.GET_MULTIFILTER_RESULT_SUCCESS,
    requestData,
  };
};

export const getMultiFilterResultApiCalledFinished = () => {
  return {
    type: actionTypes.GET_MULTIFILTER_RESULT_API_CALL_FINISHED,
  };
};
// import { getFavoritesInNavFail, getFavoritesInNavStart, getFavoritesInNavSuccess } from './actions';
import * as actionTypes from './actionTypes';
/**
 *  Initial State filteredData Reducer
 */
const initialState = {
  loading: false,
  error: null,
  message: '',
  data: [],
  payload: {},
  favorites: [],
  favoriteItems: [],
  totalFavorites: 0,
  itineraryList: [],
  CTALink: {},
  itineraryMapCTA: {},
  shouldResetFilter: false
};
/**
 * On Get filteredData Start Action
 */
const getFilterdDataStart = state => {
  return { ...state, error: null, loading: true, payload: {} };
};

/**
 * On Get filteredData Success Action
 */
const getFilterdDataSuccess = (state, action) => {
  return {
    ...state,
    data: { ...action.payload.results },
    error: null,
    loading: false,
    payload: { ...action.payload.payload },
  };
};

/**
 * On Get filteredData Fail Action
 */
const getFilterdDataFail = (state, action) => {
  return {
    ...state,
    error: true,
    loading: false,
    message: action.message,
    payload: {},
  };
};

/**
 * On Get filteredData Clear Action
 */
const getFilterdDataClear = state => {
  return {
    ...state,
    error: false,
    loading: false,
    data: {},
    payload: {},
  };
};

/**
 * On Get filteredData Start Action
 */
const getFavoritesInNavStart = state => {
  return { ...state, error: null, loading: true };
};

/**
 * On Get filteredData Success Action
 */
const getFavoritesInNavSuccess = (state, action) => {
  return {
    ...state,
    favorites: action.payload.favorites,
    totalFavorites: action.payload.totalFavorites,
    favoriteItems: action.payload.favoriteItems,
    error: null,
    loading: false,
  };
};

/**
 * On Get filteredData Fail Action
 */
const getFavoritesInNavFail = (state, action) => {
  return {
    ...state,
    error: true,
    loading: false,
    message: action.message,
  };
};


const getFavoriteDataClear = state => {
  return {
    ...state,
    error: false,
    loading: false,
    favorites: [],
    totalFavorites: 0,
  };
};


const removeFilteredDataById = (state, action) => {
  const filteredResults = state?.data?.results.filter(item => item.itemUniqueKey !== action.payload.filteredId)

  return {
    ...state,
    data: {
      ...state.data,
      results: filteredResults,
      totalResults: state.data.totalResults - 1,
    },
  };
};

const addItinerary = (state, action) => {
  return {
    ...state,
    itineraryList: action.payload,
  };
};

const setViewAllFavLink = (state, action) => {
  return {
    ...state,
    CTALink: action.payload,
  };
};
const setPreviewItineraryLink = (state, action) => {
  return {
    ...state,
    itineraryMapCTA: action.payload,
  };
};

const shouldResetFiltersForAdCalendar = (state, action) => {
  return {
    ...state,
    shouldResetFilter: action.data,
  };
};

/**
 * Update filtersList Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FILTERD_DATA_START:
      return getFilterdDataStart(state);
    case actionTypes.GET_FILTERD_DATA_SUCCESS:
      return getFilterdDataSuccess(state, action);
    case actionTypes.GET_FILTERD_DATA_FAIL:
      return getFilterdDataFail(state, action);
    case actionTypes.CLEAR_FILTERD_DATA:
      return getFilterdDataClear(state, action);
    case actionTypes.REMOVE_FILTERED_DATA_BY_ID:
      return removeFilteredDataById(state, action);

    case actionTypes.GET_FAVORITES_IN_NAV_DATA_START:
      return getFavoritesInNavStart(state);
    case actionTypes.GET_FAVORITES_IN_NAV_DATA_SUCCESS:
      return getFavoritesInNavSuccess(state, action);
    case actionTypes.GET_FAVORITES_IN_NAV_DATA_FAIL:
      return getFavoritesInNavFail(state, action);
    case actionTypes.CLEAR_FAVORITES_IN_NAV_DATA:
      return getFavoriteDataClear(state, action);
    case actionTypes.ADD_ITINERARY:
      return addItinerary(state, action);
    case actionTypes.SET_VIEW_ALL_FAV_LINK:
      return setViewAllFavLink(state, action);
    case actionTypes.SET_PREVIEW_ITINERARY_LINK:
      return setPreviewItineraryLink(state, action);
    case actionTypes.SHOULD_RESET_FILTERS_FOR_AD_CALENDAR:
      return shouldResetFiltersForAdCalendar(state, action);
    default:
      return state;
  }
};

export const GET_FILTERSINFO_START = 'GET_FILTERSINFO_START';
export const GET_FILTERSINFO_SUCCESS = 'GET_FILTERSINFO_SUCCESS';
export const GET_FILTERSINFO_FAIL = 'GET_FILTERSINFO_FAIL';
export const GET_FILTERSINFO_DATA = 'GET_FILTERSINFO_DATA';
export const UPDATE_FILTERSINFO_DATA = 'UPDATE_FILTERSINFO_DATA';
export const UPDATE_MULTIPLE_FILTERSINFO_DATA =
  'UPDATE_MULTIPLE_FILTERSINFO_DATA';
export const UPDATE_SELECTED_FILTER_DATA_MOBILE =
  'UPDATE_SELECTED_FILTER_DATA_MOBILE';
export const UPDATE_QUERY_FILTERSINFO_DATA = 'UPDATE_QUERY_FILTERSINFO_DATA';

export const GET_FILTERD_DATA_START = 'GET_FILTERD_DATA_START';
export const GET_FILTERD_DATA_SUCCESS = 'GET_FILTERD_DATA_SUCCESS';
export const GET_FILTERD_DATA_FAIL = 'GET_FILTERD_DATA_FAIL';
export const CLEAR_FILTERD_DATA = 'CLEAR_FILTERD_DATA';
export const GET_FILTERD_DATA = 'GET_FILTERD_DATA';
export const REMOVE_FILTERED_DATA_BY_ID = 'REMOVE_FILTERED_DATA_BY_ID';

export const GET_FAVORITES_IN_NAV_DATA_START = 'GET_FAVORITES_IN_NAV_DATA_START';
export const GET_FAVORITES_IN_NAV_DATA_SUCCESS = 'GET_FAVORITES_IN_NAV_DATA_SUCCESS';
export const GET_FAVORITES_IN_NAV_DATA_FAIL = 'GET_FAVORITES_IN_NAV_DATA_FAIL';
export const CLEAR_FAVORITES_IN_NAV_DATA = 'CLEAR_FILTERD_DATA';

export const GET_FAVORITES_IN_NAV_DATA = 'GET_FAVORITES_IN_NAV_DATA';

export const UPDATE_SELECTED_FILTER_DATA = 'UPDATE_SELECTED_FILTER_DATA';
export const CLEAR_SELECTED_FILTER_DATA = 'CLEAR_SELECTED_FILTER_DATA';
export const CLEAR_ALL_CLICKED = 'CLEAR_ALL_CLICKED';
export const ADD_ITINERARY = 'ADD_ITINERARY';
export const SET_VIEW_ALL_FAV_LINK = 'SET_VIEW_ALL_FAV_LINK';
export const SET_PREVIEW_ITINERARY_LINK = 'SET_PREVIEW_ITINERARY_LINK';

export const UPDATE_FILTERS_FOR_AD_CALENDAR_DATA = 'UPDATE_FILTERS_FOR_AD_CALENDAR_DATA'
export const SHOULD_RESET_FILTERS_FOR_AD_CALENDAR = 'SHOULD_RESET_FILTERS_FOR_AD_CALENDAR'

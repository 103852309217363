import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';
import ArrowForwardIosSharp from '@material-ui/icons/ArrowForwardIosSharp';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';
import ArrowBackIosSharp from '@material-ui/icons/ArrowBackIosSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import CloseIcon from '@material-ui/icons/Close';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import StarIcon from '@material-ui/icons/Star';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { FacebookIcon } from '../../../../styles/svgIcons/FacebookIcon';
import { TwitterIcon } from '../../../../styles/svgIcons/TwitterIcon';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CheckIcon from '@material-ui/icons/Check';
import { CloseIconNew } from '../../../../styles/svgIcons/CloseIconNew'
import { FilterIcon } from '../../../../styles/svgIcons/FilterIcon'
import { RemoveIcon } from '../../../../styles/svgIcons/RemoveIcon'


import useStyles from './style';
import {
  Blizzard,
  BlowingSnow,
  Cloudy,
  Fog,
  FreezingDrizzle,
  FreezingFog,
  HeavyFreezingDrizzle,
  HeavyRain,
  HeavyRainsAtTimes,
  LightDrizzle,
  LightFreezingRain,
  LightRain,
  Mist,
  ModerateRain,
  ModerateRainAtTimes,
  Overcast,
  PartlyCloudy,
  PatchyFreezingDrizzlePossible,
  PatchyLightDrizzle,
  PatchyLightRain,
  PatchyRainPossible,
  PatchySleetPossible,
  PatchySnowPossible,
  Sunny,
  ThunderyOutbreaksPossible,
} from '../../../../styles/svgIcons/WeatherIcons';
import { FallbackCheckIcon } from '../../../../styles/svgIcons/FallbackCheckIcon';
import FailureIcon from '../../../../styles/svgIcons/Failure';
import SuccessIcon from '../../../../styles/svgIcons/Success';
import Language from '../../../../styles/svgIcons/Language';
import Favorite from '../../../../styles/svgIcons/Favorite';

import SearchHeaderIcon from '../../../../styles/svgIcons/SearchIcon';
import MapIcon from '../../../../styles/svgIcons/MapIcon';


import {
  Twitter,
  Instagram,
  Youtube,
  Facebook,
  VK,
  Youku,
  WeChat,
  Weibo,
  weiboicon,
  shareicon,
  facebookIconColored,
  linkedInIconColored,
  mailIconColored,
  twitterIconColored,
  pinterestIconColored,
} from '../../../../styles/svgIcons/SocialIcons';
import { PlayIcon } from '../../../../styles/svgIcons/PlayIcon';
import SuperGraphics from '../../../../styles/svgIcons/SuperGraphics';
import { DollarSign, ViewEye, ArrowRoundedDown, ZoomIn, ZoomOut, LoaderLogo } from '../../../../styles/svgIcons/Miscellaneous';

const DctSvgIcon = props => {
  const classes = useStyles();

  switch (props.name && props.name.toLowerCase()) {
    case 'arrowforwardiossharp':
      return <ArrowForwardIosSharp {...props} />;
    case 'arrowbackiossharp':
      return <ArrowBackIosSharp {...props} />;
    case 'arrowforwardsharpicon':
      return <ArrowForwardSharpIcon {...props} className={classes.root} />;
    case 'searchheadericon':
      return <SearchHeaderIcon {...props} />;
    case 'mapicon':
      return <MapIcon {...props} />;
    case 'searchsharpicon':
      return <SearchSharpIcon {...props} />;
    case 'closeicon':
      return <CloseIcon {...props} />;
    case 'closeiconnew':
      return <CloseIconNew {...props} />;
    case 'filtericon':
      return <FilterIcon {...props} />;
    case 'removeicon':
      return <RemoveIcon {...props} />;
    case 'favoriteicon':
      return <Favorite {...props} />;
    case 'formatalignrighticon':
      return <FormatAlignRightIcon {...props} />;
    case 'arrowupwardicon':
      return <ArrowUpwardIcon {...props} />;
    case 'locationonicon':
      return <LocationOnIcon {...props} />;
    case 'filterlisticon':
      return <FilterListIcon {...props} />;
    case 'expandmoreicon':
      return <ExpandMoreIcon {...props} />;
    case 'morehorizicon':
      return <MoreHorizIcon {...props} />;
    case 'keyboardarrowdownicon':
      return <KeyboardArrowDownIcon {...props} />;
    case 'keyboardarrowupicon':
      return <KeyboardArrowUpIcon {...props} />;
    case 'playarrowicon':
      return <PlayIcon {...props} />;
    case 'cameraalticon':
      return <CameraAltIcon {...props} />;
    case 'imageoutlinedicon':
      return <ImageOutlinedIcon {...props} />;
    case 'accesstimeicon':
      return <AccessTimeIcon {...props} />;
    case 'favoritebordericon':
      return <FavoriteBorderIcon {...props} />;
    case 'infooutlinedicon':
      return <InfoOutlinedIcon {...props} />;
    case 'staricon':
      return <StarIcon {...props} />;
    case 'arrowforwardiosicon':
      return <ArrowForwardIosIcon {...props} />;
    case 'blizzard':
      return <Blizzard {...props} />;
    case 'blowingsnow':
      return <BlowingSnow {...props} />;
    case 'cloudy':
      return <Cloudy {...props} />;
    case 'fog':
      return <Fog {...props} />;
    case 'freezingdrizzle':
      return <FreezingDrizzle {...props} />;
    case 'freezingfog':
      return <FreezingFog {...props} />;
    case 'heavyfreezingdrizzle':
      return <HeavyFreezingDrizzle {...props} />;
    case 'heavyrain':
      return <HeavyRain {...props} />;
    case 'heavyrainsattimes':
      return <HeavyRainsAtTimes {...props} />;
    case 'lightdrizzle':
      return <LightDrizzle {...props} />;
    case 'lightfreezingrain':
      return <LightFreezingRain {...props} />;
    case 'lightrain':
      return <LightRain {...props} />;
    case 'mist':
      return <Mist {...props} />;
    case 'moderaterain':
      return <ModerateRain {...props} />;
    case 'moderaterainattimes':
      return <ModerateRainAtTimes {...props} />;
    case 'overcast':
      return <Overcast {...props} />;
    case 'partlycloudy':
      return <PartlyCloudy {...props} />;
    case 'patchyfreezingdrizzlepossible':
      return <PatchyFreezingDrizzlePossible {...props} />;
    case 'patchylightdrizzle':
      return <PatchyLightDrizzle {...props} />;
    case 'patchylightrain':
      return <PatchyLightRain {...props} />;
    case 'patchyrainpossible':
      return <PatchyRainPossible {...props} />;
    case 'patchysleetpossible':
      return <PatchySleetPossible {...props} />;
    case 'patchysnowpossible':
      return <PatchySnowPossible {...props} />;
    case 'sunny':
      return <Sunny {...props} />;
    case 'thunderyoutbreakspossible':
      return <ThunderyOutbreaksPossible {...props} />;
    case 'twittericon':
      return <TwitterIcon {...props} />;
    case 'instagramicon':
      return <InstagramIcon {...props} />;
    case 'facebookicon':
      return <FacebookIcon {...props} />;
    case 'youtubeicon':
      return <YouTubeIcon {...props} />;
    case 'fallbackcheckicon':
      return <FallbackCheckIcon />;
    case 'successicon':
      return SuccessIcon;
    case 'failureicon':
      return FailureIcon;
    case 'twitter':
      return Twitter;
    case 'instagram':
      return Instagram;
    case 'facebook':
      return Facebook;
    case 'youtube':
      return Youtube;
    case 'weibo':
      return Weibo;
    case 'wechat':
      return WeChat;
    case 'youku':
      return Youku;
    case 'vk':
      return VK;
    case 'weiboicon':
      return weiboicon;
    case 'facebookiconcolored':
      return facebookIconColored;
    case 'linkediniconcolored':
      return linkedInIconColored;
    case 'mailiconcolored':
      return mailIconColored;
    case 'twittericoncolored':
      return twitterIconColored;
    case 'pinteresticoncolored':
      return pinterestIconColored;
    case 'checkicon':
      return <CheckIcon {...props} />;
    case 'language':
      return Language;
    case 'shareicon':
      return shareicon;
    case 'dollarsign':
      return <DollarSign {...props} />;
    case 'zoomin':
      return <ZoomIn {...props} />;
    case 'zoomout':
      return <ZoomOut {...props} />;
    case 'vieweye':
      return <ViewEye {...props} />;
    case 'arrowroundeddown':
      return <ArrowRoundedDown {...props} />;
    case 'supergraphics':
      return <SuperGraphics {...props} />;
    case 'loaderlogo':
      return <LoaderLogo {...props} />;
    default:
      return <SvgIcon viewBox={props.viewBox || '0 0 48 48'} {...props} />;
  }
};

DctSvgIcon.propTypes = {
  name: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
};

export default DctSvgIcon;

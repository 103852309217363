import * as actionTypes from './actionTypes';
import { getFilterCount } from '../../../../../utils/utility';

/**
 *  Initial State selectedFilters Reducer
 */
const initialState = {
  data: {},
  filterCount: 0,
};

/**
 * On Get selectedFilters add Action
 */
const updateFilterData = (state, action) => {
  const filters = action.data.filters
  const filterCount = getFilterCount(filters, action.data.isAdCalendar);
  return {
    ...state,
    data: { ...filters },
    filterCount: filterCount,
  };
};

/**
 * On Get selectedFilters clear Action
 */
const clearFilterData = state => {
  return {
    ...state,
    data: {},
    filterCount: 0,
  };
};

/**
 * Update selectedFilters Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_FILTER_DATA:
      return updateFilterData(state, action);
    case actionTypes.CLEAR_SELECTED_FILTER_DATA:
      return clearFilterData(state, action);
    default:
      return state;
  }
};

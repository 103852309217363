import * as actionTypes from './actionTypes';

const initialState = {
  filterSelected: [],
  multiFilterData: null,
  isFetching: false,
}

const getMultiFilterResultSuccess = (state, action) => {
  const filters = action.requestData.filters.map(val => val.value)
  return {
    ...state,
    multiFilterData: action.requestData.items,
    filterSelected: filters
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MULTIFILTER_RESULT_API_CALL_START:
      return { ...state, isFetching: true };
    case actionTypes.GET_MULTIFILTER_RESULT_SUCCESS:
      return getMultiFilterResultSuccess(state, action);
    case actionTypes.GET_MULTIFILTER_RESULT_API_CALL_FINISHED:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};
import { all, put, takeLatest, delay } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import fetchService from '../../../../../services/FetchService';
import getAppConfig from '../../../../utils/getAppConfig';

const apiUrl = getAppConfig();

export function* getMultiFilterResultSaga({ requestData }) {
  yield put(actions.getMultiFilterResultApiCalledStart());
  try {
    let response = yield fetchService({
      url: apiUrl.SOLR_SEARCH_API,
      method: 'POST',
      data: requestData,
    });

    if (
      response &&
      response.data &&
      response.data.data &&
      Array.isArray(response.data.data.results)
    ) {
      yield put(actions.getMultiFilterResultSuccess({ items: response.data.data.results, filters: requestData.filters }));
      yield delay(500);
      yield put(actions.getMultiFilterResultApiCalledFinished());
    }
  } catch (error) {
    yield put(actions.getMultiFilterResultApiCalledFinished());
    console.log('error', error)
  }
}

export function* watchMultiFilterResult() {
  yield all([
    takeLatest(actionTypes.GET_MULTIFILTER_RESULT_START, getMultiFilterResultSaga),
  ]);
}

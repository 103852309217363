import { fork } from 'redux-saga/effects';
// import { watchUsersList } from '../global/containers/common/TestComponent/redux/saga';
import { watchLiveWeatherInfo } from '../global/components/organism/LiveWeatherInfo/redux/saga';
import { watchSmartListSaga } from '../global/containers/common/SmartList/redux/saga';
import { watchGetEventList } from '../global/containers/common/CollageType1/redux/saga';
import { watchSimilarSearch } from '../global/containers/common/SimilarSearch/redux/saga';
import { watchMultiFilterResult } from '../global/containers/common/MultiFilteredLayout/redux/saga';
import {
  watchFiltersInfo,
  watchFilterdInfo,
  watchFavInNav,
} from '../global/containers/common/Filters/redux/saga';

import {
  watchExploreMap,
} from '../global/containers/common/ExploreMap/redux/saga';
import { watchItineraryMap } from '../global/containers/common/ItineraryBuilderMap/redux/saga';

export default function* rootSaga() {
  // yield fork(watchUsersList);
  yield fork(watchLiveWeatherInfo);
  yield fork(watchSmartListSaga);
  yield fork(watchGetEventList);
  yield fork(watchExploreMap);
  yield fork(watchFiltersInfo);
  yield fork(watchFilterdInfo);
  yield fork(watchFavInNav);
  yield fork(watchSimilarSearch);
  yield fork(watchMultiFilterResult);
  yield fork(watchItineraryMap);
}

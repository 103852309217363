import * as actionTypes from './actionTypes';
/**
 *  Initial Similar Search Reducer
 */
const initialState = {
  otherBranchesLoading: false,
  otherBranchesError: null,
  otherBranchesData: [],
  otherBranchesMessage: '',

  moreLikeThisMessage: '',
  moreLikeThisLoading: false,
  moreLikeThisError: null,
  moreLikeThisTotalResult: 0,
  moreLikeThisData: [],
};
/**
 * On Get Other Branches Start Action
 */
const getOtherBranchesStart = state => {
  return { ...state, otherBranchesError: null, otherBranchesLoading: true };
};

/**
 * On Get Other Branches Success Action
 */
const getOtherBranchesSuccess = (state, action) => {
  return {
    ...state,
    otherBranchesData: action.payload,
    otherBranchesError: null,
    otherBranchesLoading: false,
  };
};

/**
 * On Get Other Branches Fail Action
 */
const getOtherBranchesFail = (state, action) => {
  return {
    ...state,
    otherBranchesError: true,
    otherBranchesLoading: false,
    otherBranchesMessage: action.payload,
  };
};

/**
 * On Get More Like This Start Action
 */
const getMoreLikeThisStart = state => {
  return { ...state, moreLikeThisError: null, moreLikeThisLoading: true };
};

/**
 * On Get More Like This Success Action
 */
const getMoreLikeThisSuccess = (state, action) => {
  return {
    ...state,
    moreLikeThisData: action.payload.results,
    moreLikeThisTotalResult: action.payload.totalResults,
    moreLikeThisError: null,
    moreLikeThisLoading: false,
  };
};

/**
 * On Get More Like This Fail Action
 */
const getMoreLikeThisFail = (state, action) => {
  return {
    ...state,
    moreLikeThisError: true,
    moreLikeThisLoading: false,
    moreLikeThisMessage: action.payload,
  };
};

/**
 * Update SimilarSearch Reducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    // Other Branches
    case actionTypes.GET_OTHER_BRANCHES_START:
      return getOtherBranchesStart(state, action);
    case actionTypes.GET_OTHER_BRANCHES_SUCCESS:
      return getOtherBranchesSuccess(state, action);
    case actionTypes.GET_OTHER_BRANCHES_FAIL:
      return getOtherBranchesFail(state, action);

    //More Like this
    case actionTypes.GET_MORE_LIKE_THIS_START:
      return getMoreLikeThisStart(state, action);
    case actionTypes.GET_MORE_LIKE_THIS_SUCCESS:
      return getMoreLikeThisSuccess(state, action);
    case actionTypes.GET_MORE_LIKE_THIS_FAIL:
      return getMoreLikeThisFail(state, action);
    default:
      return state;
  }
};

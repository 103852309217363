/* eslint-disable max-len */
import React from 'react';

const Language = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M12,23C12,23,12,23,12,23C5.9,23,1,18.1,1,12C1,5.9,5.9,1,12,1c0,0,0,0,0,0c6.1,0,11,4.9,11,11  C23,18.1,18.1,23,12,23C12,23,12,23,12,23z M16.9,13c-0.2,2.8-1.2,5.4-2.7,7.7c3.6-0.9,6.3-4,6.7-7.7H16.9z M3.1,13  c0.4,3.7,3.1,6.8,6.7,7.7C8.2,18.4,7.3,15.8,7,13H3.1z M9.1,13c0.2,2.7,1.3,5.3,2.9,7.4c1.7-2.2,2.7-4.7,2.9-7.4H9.1z M17,11h4  c-0.4-3.7-3.1-6.8-6.7-7.7C15.8,5.6,16.7,8.2,17,11z M9.1,11h5.9c-0.2-2.7-1.3-5.3-2.9-7.4C10.3,5.7,9.3,8.3,9.1,11z M3.1,11h4  c0.2-2.8,1.2-5.4,2.7-7.7C6.2,4.2,3.5,7.3,3.1,11z" />
  </svg>
);

export default Language;

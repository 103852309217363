import { variantItems } from './variants';
import { variantItemsAR } from './variants_ar';
import { variantItemsZH } from './variants_zh';
import { variantItemsRU } from './variants_ru';
import { variantItemsKO } from './variants_ko';
import { fontFamilyEN, fontFamilyAR, fontFamilyZH, fontFamilyHE } from './font';
import { arabicLang, chineseLang, russianLang, hebrewLang, koreanLang } from './constants';

export function getVariant(selectedLang) {
  if (selectedLang === arabicLang) {
    return variantItemsAR;
  } else if (selectedLang === chineseLang ||
    selectedLang === hebrewLang) {
    return variantItemsZH;
  } else if (selectedLang === koreanLang) {
    return variantItemsKO;
  } else {
    return variantItems;
  }
}

export function getFontFamily(selectedLang) {
  return fontFamilyEN;
}
